<template>
	<!-- Modal Component -->
	<b-modal
		id="expired-password"
		ref="modal"
		title="Expired Password"
		@ok="handleOk"
		@shown="clearEmail"
	>
		<p class="my-4">
			Your password has been expired. Please Enter your email address linked to
			your account. You will receive an email with the link to reset your
			password.
		</p>
		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-form-input
				v-model="email"
				name="Email"
				type="email"
				placeholder="Enter your email address"
				required
				v-validate="'required|email'"
			></b-form-input>
			<span v-show="errors.has('Email')" class="help-block">{{
				errors.first('Email')
			}}</span>
		</b-form>
	</b-modal>
</template>

<script>
import { firebase } from '@/config/firebase';

export default {
	name: 'expired-password',
	data() {
		return {
			email: '',
		};
	},
	methods: {
		clearEmail() {
			this.email = '';
		},
		handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			if (!this.email) {
				this.$toaster.warning('Please enter your email address');
			} else {
				this.$validator.validateAll().then((isValid) => {
					if (isValid) {
						this.handleSubmit();
					}
				});
			}
		},
		handleSubmit() {
			let auth = firebase.auth();
			auth
				.sendPasswordResetEmail(this.email)
				.then(() => {
					this.$refs.modal.hide();
					this.$toaster.success('Reset password email was sent!');
				})
				.catch(() => {
					this.$toaster.warning(
						'Error sending reset password email. Please try again.'
					);
				});
		},
	},
};
</script>
